import { useContext, createContext } from "react"

export const FormulaContext = createContext<
  "gamma_nl" | "gamma_be" | "karwei_nl" | null
>(null)

export function useFormula() {
  const formula = useContext(FormulaContext)

  if (!formula) {
    throw new Error("Please use Formula context with provider")
  }

  return formula
}
