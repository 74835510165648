import i18next from "i18next"
import BrowserLanguageDetector, {
  CustomDetector,
} from "i18next-browser-languagedetector"
import backend from "i18next-http-backend"
import Cookies from "js-cookie"
import { initReactI18next } from "react-i18next"

import * as formatters from "@intergamma/formatters"
import { Theme } from "@intergamma/theme"

interface II18nProps {
  theme: Theme
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: string[]
}

const domainMatch = document.location.href.match(/\/\/([^:/]+)/) || []
const domain = domainMatch[1] || "gamma.nl" // Fallback to gamma for local development
const env = domain.indexOf("acceptatie") >= 0 ? "acc" : "www"

const country = /gamma\.be$/.test(domain) ? "be" : "nl"
const brand = /karwei\.nl$/.test(domain) ? "karwei" : "gamma"
const urlPrefix = env === "acc" ? "acceptatie." : ""
const baseDomain = `${urlPrefix}${brand}.${country}`

export function initializeI18n({ theme, locales }: II18nProps) {
  if (locales.length > 1) {
    const languageDetector = new BrowserLanguageDetector()

    languageDetector.addDetector(igLanguageCookieDetector)

    i18next.use(languageDetector)
  }

  i18next
    .use(backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      },
      debug: false,
      defaultNS: "common",
      detection: {
        caches: ["igLanguageCookieDetector"],
        cookieDomain: env === "acc" ? baseDomain : `.${baseDomain}`,
        lookupCookie: `${env}-${brand}-${country}-LOCALE`,
        lookupQuerystring: "locale",
        order: [
          "querystring",
          "igLanguageCookieDetector",
          "navigator",
          "htmlTag",
        ],
      },
      fallbackLng: "nl",
      interpolation: {
        defaultVariables: { ...theme },
        escapeValue: false,
        format: (value, format, lng) =>
          typeof formatters[format as string] === "function"
            ? formatters[format as string](value, lng, i18next)
            : value,
      },
      missingInterpolationHandler: (text) => {
        // eslint-disable-next-line no-console
        console.warn(
          `Missing value for translation interpolation. Translation content: ${text}`
        )
      },
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        // eslint-disable-next-line no-console
        console.warn(
          `Missing ${lng} translation with key "${key}" and namespace "${ns}". Falling back to: "${fallbackValue}"`
        )
      },
      ns: ["common"],
      react: {
        transKeepBasicHtmlNodesFor: ["strong", "i", "p"],
        transSupportBasicHtmlNodes: true,
        useSuspense: false,
      },
      saveMissing: true,
      supportedLngs: locales,
    })
}

const igLanguageCookieDetector: CustomDetector = {
  name: "igLanguageCookieDetector",

  lookup(options) {
    if (options.lookupCookie) {
      return Cookies.get(options.lookupCookie)?.replace("_", "-")
    }

    return undefined
  },

  cacheUserLanguage(lng, options) {
    if (options.lookupCookie) {
      Cookies.set(options.lookupCookie, lng.replace("-", "_"), {
        expires: 365,
        domain: options.cookieDomain,
        sameSite: "strict",
      })
    }
  },
}
