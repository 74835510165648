import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Password, Label, Button, Message } from "@intergamma/form"
import { CredentialRoles, validateCredentials } from "@intergamma/kiosk-util"

import { StyledContainer } from "./StyledContainer"

export interface ILoginFormProps {
  children: (role: CredentialRoles) => ReactElement | null
}

export function LoginForm({ children }: ILoginFormProps) {
  const { isLoading, error, isLoggedIn, login, role, password, setPassword } =
    useKioskLoginForm()

  const { t } = useTranslation(["ig-form", "ig-kiosk-admin"])

  if (!isLoggedIn) {
    return (
      <StyledContainer>
        <h1>{t("ig-kiosk-admin:LoginTitle")}</h1>
        <form onSubmit={login} noValidate autoComplete="off">
          <Label htmlFor="password">{t("ig-kiosk-admin:LoginLabel")}</Label>
          <Password
            id="password"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            placeholder={t("ig-kiosk-admin:LoginPlaceholder")}
            t={t}
          />
          <StyledButton
            isLoading={isLoading}
            disabled={isLoading}
            complete={isLoading}
            type="submit"
          >
            {t("ig-kiosk-admin:LoginSubmitButton")}
          </StyledButton>
          {isLoading && <Message muted>{t("ig-kiosk-admin:Loading")}</Message>}
          {error && <Message error>{error}</Message>}
        </form>
      </StyledContainer>
    )
  }

  return children(role)
}

function useKioskLoginForm() {
  const { t } = useTranslation(["ig-kiosk-admin"])
  const [password, setPassword] = useState("")
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [role, setRole] = useState<CredentialRoles>(null)

  useEffect(() => {
    setIsLoggedIn(!!role)
  }, [role])

  async function login(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setError(undefined)
    setIsLoading(true)

    const [response] = await Promise.all([
      validateCredentials({ password }),
      sleep(350), // If call is too fast wait for 350ms to indicate loading state for user
    ])

    setIsLoading(false)

    if (!response) {
      setError(t("ig-kiosk-admin:IncorrectPassword"))
    }

    setRole(response)
  }

  return { isLoggedIn, login, isLoading, role, password, setPassword, error }
}

const StyledButton = styled(Button)`
  margin: 1rem 0;
`

async function sleep(timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}
