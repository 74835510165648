import { FC } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Message } from "@intergamma/form"
import {
  SetKioskDataError,
  SetKioskDataErrorField,
} from "@intergamma/kiosk-util"

export interface IConfigFormErrorMessageProps {
  error: null | SetKioskDataError
  fieldName: SetKioskDataErrorField
}

export const ConfigFormErrorMessage: FC<IConfigFormErrorMessageProps> = ({
  error,
  fieldName,
}) => {
  const { t } = useTranslation()

  if (!error) {
    return null
  }

  if (error.fieldName !== fieldName) {
    return null
  }

  return (
    <StyledMessage error>
      {t(`ig-kiosk-admin:ErrorMessage-${error.fieldName}-${error.message}`)}
    </StyledMessage>
  )
}

const StyledMessage = styled(Message)`
  margin-bottom: 1rem;
`
