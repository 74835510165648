import { useState } from "react"

import {
  IKioskAdminData,
  setKioskData,
  SetKioskDataError,
} from "@intergamma/kiosk-util"

export function useKioskSubmit(afterSubmit: () => void) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setIsError] = useState<SetKioskDataError | null>(null)

  const [showSuccess, setShowSuccess] = useState(false)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)

    e.preventDefault()
    const form = e.target as HTMLFormElement

    const formData = [...new FormData(form).entries()] as unknown as [
      keyof IKioskAdminData,
      string
    ][]

    const kioskData = mapFormDataToKioskData(formData)

    try {
      await setKioskData({
        ...kioskData,
        debugEnabledTime: kioskData.debugEnabledTime ?? null,
      })

      setIsError(null)

      setTimeout(() => {
        setIsLoading(false)
        setShowSuccess(true)
        afterSubmit()
      }, 300) // Set timeout to delay the get request as the calls are too fast and you don't get feedback as a user otherwise

      setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    } catch (submitError) {
      setShowSuccess(false)

      if (submitError instanceof SetKioskDataError) {
        setIsError(submitError)
      }

      setIsLoading(false)
    }
  }

  return { isLoading, onSubmit, showSuccess, error }
}

function mapFormDataToKioskData(formData: [keyof IKioskAdminData, string][]) {
  return formData.reduce<IKioskAdminData>((accu, entry) => {
    const entryKey = entry[0]
    const entryValue = validateEntryValue(entry)

    return {
      ...accu,
      [entryKey]: entryValue,
    }
  }, {} as IKioskAdminData)
}

function validateEntryValue(
  entry: [keyof IKioskAdminData, string]
): string | Date {
  if (entry[0] === "store") {
    return entry[1].toUpperCase().trim()
  }

  if (entry[0] === "debugEnabledTime") {
    return new Date()
  }

  return entry[1]
}
