import React from "react"

import ReactDOM from "react-dom"
import { ThemeProvider } from "styled-components" // Fallback to gamma for local development

import { gamma_be, gamma_nl, karwei_nl, GlobalStyle } from "@intergamma/theme"

import { App } from "./App"
import { FormulaContext } from "./Formula"
import { initializeI18n } from "./i18n"

const domainMatch = document.location.href.match(/\/\/([^:/]+)/) || []
const domain = domainMatch[1] || "gamma.nl"

const themes = {
  karwei_nl,
  gamma_be,
  gamma_nl,
}

const country = /gamma\.be$/.test(domain) ? "be" : "nl"
const brand = /karwei\.nl$/.test(domain) ? "karwei" : "gamma"

const currentFormulaKey = `${brand}_${country}` as
  | "gamma_nl"
  | "gamma_be"
  | "karwei_nl"

const activeTheme = themes[currentFormulaKey]
const assetsPublicPath = `${process.env.PUBLIC_URL}/${activeTheme.name}`

const locales = {
  gamma_nl: ["nl"],
  gamma_be: ["nl-BE", "fr-BE"],
  karwei_nl: ["nl"],
}

initializeI18n({
  locales: locales[currentFormulaKey],
  theme: activeTheme,
})

ReactDOM.render(
  <React.StrictMode>
    <FormulaContext.Provider value={currentFormulaKey}>
      <ThemeProvider theme={activeTheme}>
        <GlobalStyle assetsPublicPath={assetsPublicPath} useWoff2Font />
        <App />
      </ThemeProvider>
    </FormulaContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
