import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Container } from "@intergamma/container"

import { ConfigForm } from "./components/ConfigForm"
import { Header } from "./components/Header"
import { LoadingIndicator } from "./components/LoadingIndicator"
import { LoginForm } from "./components/LoginForm"

export function App() {
  const { ready } = useTranslation(["ig-kiosk-admin", "ig-form"])

  if (!ready) {
    return (
      <StyledContainer>
        <LoadingIndicator />
      </StyledContainer>
    )
  }

  return (
    <>
      <Header />
      <LoginForm>
        {(role) => {
          if (!role) {
            return null
          }

          return <ConfigForm kioskRole={role} />
        }}
      </LoginForm>
    </>
  )
}

const StyledContainer = styled(Container)`
  height: 100vh;
`
