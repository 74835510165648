import { FC } from "react"

import { ChevronDown, ChevronUp } from "react-feather"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import Anchor from "@intergamma/anchor"
import { Dropdown, DropdownContent } from "@intergamma/dropdown"
import { List, ListItem } from "@intergamma/list"

export const LanguageSelector: FC = (props) => {
  const { t, i18n } = useTranslation("ig-header")

  const changeLanguage = (lng: string) => i18n.changeLanguage(lng)

  return (
    <LanguageSelectorContainer>
      <Dropdown
        {...props}
        render={({ isActive, toggle, close, contentRef, isClosing }) => (
          <>
            <TopLink style={{ cursor: "pointer" }} onClick={toggle}>
              <strong>{t("ig-header:Language")}</strong>
              {isActive ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </TopLink>
            <DropdownContent
              isActive={isActive}
              isClosing={isClosing}
              isRaised
              align="right"
              tabIndex={-1}
              ref={contentRef}
            >
              <DropdownContainer>
                <List>
                  <ListItem>
                    <DropdownLink
                      lang="nl"
                      href="/nl"
                      hrefLang="nl-BE"
                      onClick={(e) => {
                        e.preventDefault()
                        close()
                        changeLanguage("nl-BE")
                      }}
                    >
                      Nederlands
                    </DropdownLink>
                  </ListItem>
                  <ListItem>
                    <DropdownLink
                      lang="fr"
                      href="/fr"
                      hrefLang="fr-BE"
                      onClick={(e) => {
                        e.preventDefault()
                        close()
                        changeLanguage("fr-BE")
                      }}
                    >
                      Français
                    </DropdownLink>
                  </ListItem>
                </List>
              </DropdownContainer>
            </DropdownContent>
          </>
        )}
      />
    </LanguageSelectorContainer>
  )
}

const DropdownContainer = styled.div`
  padding: 20px;
  font-size: 0.875em;
`

const DropdownLink = styled(Anchor)`
  white-space: nowrap;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const TopLink = styled.a`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 5px;
  font-family: ${(props) => props.theme.baseFont};
  font-size: 0.92em;
  color: ${(props) => props.theme.brandColor};
  text-decoration: none;
  text-align: right;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
    border-radius: ${(props) => props.theme.borderRadius / 2}px;
    box-shadow: 0 0 0 3px ${(props) => props.theme.linkHighlightColor};
  }
  svg {
    margin-left: 3px;
    vertical-align: text-bottom;
    stroke-width: 3px;
  }
`

const LanguageSelectorContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 2rem;
`
