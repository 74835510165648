import { useState, useEffect } from "react"

import { differenceInMinutes } from "date-fns"

import { getKioskData, Kiosk } from "@intergamma/kiosk-util"

import { useFormula } from "../Formula"

export function useDefaultDataSet() {
  const [isLoadingDefaultDataSet, setIsLoadingDefaultDataSet] = useState(false)
  const [previousData, setPreviousData] = useState<Kiosk | null>(null)
  const formula = useFormula()

  async function reload() {
    setIsLoadingDefaultDataSet(true)

    const kioskData = await getKioskData()

    if (kioskData.isKiosk) {
      setPreviousData(kioskData)
    }

    setIsLoadingDefaultDataSet(false)
  }

  useEffect(() => {
    reload()
  }, [])

  if (isLoadingDefaultDataSet) {
    return {
      isLoadingDefaultDataSet,
      reload,
      storeDepartment: "",
      startUrl: "",
      pinTerminalId: "P400Plus-",
      store: "",
      storeUID: "",
      screenSaverVideoUrl: "Default",
      debugEnabledTime: false,
    }
  }

  if (previousData) {
    return {
      isLoadingDefaultDataSet,
      reload,
      storeDepartment: previousData.storeDepartment,
      startUrl: previousData.startUrl,
      pinTerminalId: previousData.hasPinTerminal
        ? previousData.pinTerminalId
        : "",
      store: previousData.store,
      storeUID: previousData.storeUID,
      screenSaverVideoUrl: previousData.screenSaverVideoUrl,
      debugEnabledTime: checkIsDebugEnabled(previousData.debugEnabledTime),
    }
  }

  return {
    isLoadingDefaultDataSet,
    reload,
    storeDepartment: "",
    startUrl: getStartUrlByFormula(formula),
    pinTerminalId: "P400Plus-",
    store: "",
    storeUID: "",
    screenSaverVideoUrl: "Default",
    debugEnabledTime: false,
  }
}

function getStartUrlByFormula(formula: "gamma_nl" | "gamma_be" | "karwei_nl") {
  switch (formula) {
    case "gamma_nl":
      return "https://www.gamma.nl"
    case "karwei_nl":
      return "https://www.karwei.nl"
    case "gamma_be":
      return "https://www.gamma.be"
    default:
      return ""
  }
}

function checkIsDebugEnabled(debugISO: string | null): boolean {
  if (!debugISO) {
    return false
  }

  const now = new Date()
  const date = new Date(debugISO)

  return differenceInMinutes(now, date) < 10 // If bigger than 10 minutes turn it off again. Some logic applies in extension
}
