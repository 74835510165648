import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Button } from "@intergamma/form"

import { LanguageSelector } from "./LanguageSelector"
import { StyledContainer } from "./StyledContainer"

const domainMatch = document.location.href.match(/\/\/([^:/]+)/) || []
const domain = domainMatch[1] || "gamma.nl" // Fallback to gamma for local development

const country = /gamma\.be$/.test(domain) ? "be" : "nl"

export function Header() {
  const { t } = useTranslation(["ig-kiosk-admin"])

  return (
    <StyledHeaderContainer>
      {country === "be" && <LanguageSelector />}
      <StyledLink href={process.env.REACT_APP_HOME_URL}>
        <img
          alt="Logo"
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_SKIN}/images/logo.svg`}
        />
      </StyledLink>
      <Button.Link href={process.env.REACT_APP_HOME_URL} secondary>
        {t("ig-kiosk-admin:BackToHome")}
      </Button.Link>
    </StyledHeaderContainer>
  )
}

const StyledHeaderContainer = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  align-items: center;
  margin-bottom: 3rem;
`

const StyledLink = styled.a`
  display: flex;
`
