export function getStoreDepartmentOptions(language: "nl" | "fr" | string) {
  return language !== "fr-BE" ? storeDepartmentsNl : storeDepartmentFR
}

const storeDepartmentsNl = [
  "BALIE",
  "BOUWMATERIALEN",
  "DAT VRAAG IK",
  "DEUREN",
  "ENTREE",
  "GEREEDSCHAP",
  "HOOFDPAD",
  "HOUT",
  "MAATWERK RAAMDECORATIE",
  "MEUBELPLEIN",
  "RAAMDECORATIE",
  "SANITAIR",
  "SEIZOENSPLEIN",
  "SLIM WONEN",
  "TUINPLEIN",
  "VERF",
  "VERFMENGBALIE",
  "VERLICHTING",
  "VLOEREN",
  "PW STAPELKOPERWERK",
  "PW VERF",
  "INFO DUURZAAM",
  "INFO KLUSSERVICE",
  "INFO SMARTHOME",
]

const storeDepartmentFR = [
  "SERVICE",
  "MATERIAUX DE CONSTRUCTION",
  "PORTES",
  "ENTREE",
  "OUTILLAGE",
  "PROMENADE PRINCIPALE",
  "BOIS",
  "HABILLAGE DE FENETRE SUR MESURE",
  "ILOT DE MEUBLES",
  "HABILLAGE DE FENETRE",
  "SANITAIRE",
  "ILOT SAISONNIER",
  "MAISON CONNECTEE",
  "JARDIN",
  "PEINTURE",
  "SERVICE MELANGE",
  "ECLAIRAGE",
  "SOLS",
  "PW TUYAUX ET ACCESSOIRES EN CUIVRE",
  "PW PEINTURE",
  "INFO DURABILITE",
  "INFO SERVICE DE BRICOLAGE",
  "INFO SMARTHOME",
]
