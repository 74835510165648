import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import {
  Input,
  Label,
  Select,
  Button,
  Message,
  Checkbox,
} from "@intergamma/form"
import { CredentialRoles } from "@intergamma/kiosk-util"

import { useFormula } from "../Formula"
import { useDefaultDataSet } from "../hooks/useDefaultDataSet"
import { useKioskSubmit } from "../hooks/useKioskSubmit"
import { getScreenSaverOptionsByFormula } from "../util/getScreenSaverByFormula"
import { getStoreDepartmentOptions } from "../util/getStoreDepartmentOptions"

import { ConfigFormErrorMessage } from "./ConfigFormErrorMessage"
import { LoadingIndicator } from "./LoadingIndicator"
import { StyledContainer } from "./StyledContainer"

export interface IConfigFormProps {
  kioskRole: Exclude<CredentialRoles, null>
}

export function ConfigForm({ kioskRole }: IConfigFormProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formula = useFormula()

  const {
    isLoadingDefaultDataSet,
    storeDepartment,
    startUrl,
    pinTerminalId,
    store,
    storeUID,
    reload,
    screenSaverVideoUrl,
    debugEnabledTime,
  } = useDefaultDataSet()

  const storeDepartmentOptions = useMemo(
    () => getStoreDepartmentOptions(language),
    [language]
  )

  const screenSaverOptions = useMemo(
    () => getScreenSaverOptionsByFormula(formula, language),
    [formula, language]
  )

  const {
    onSubmit,
    isLoading: isSubmittingForm,
    showSuccess,
    error,
  } = useKioskSubmit(reload)

  if (isLoadingDefaultDataSet) {
    return <LoadingIndicator />
  }

  return (
    <StyledContainer>
      <form onSubmit={onSubmit}>
        <Label htmlFor="startUrl">{t("ig-kiosk-admin:StartUrlLabel")}</Label>
        <StyledInput
          name="startUrl"
          id="startUrl"
          defaultValue={startUrl}
          required
        />
        <ConfigFormErrorMessage error={error} fieldName="startUrl" />

        {kioskRole === "admin" && (
          <Label htmlFor="pinTerminalId">
            {t("ig-kiosk-admin:PinTerminalIdLabel")}
          </Label>
        )}
        <StyledInput
          type={kioskRole === "admin" ? "text" : "hidden"}
          name="pinTerminalId"
          id="pinTerminalId"
          required
          defaultValue={pinTerminalId}
        />
        <ConfigFormErrorMessage error={error} fieldName="pinTerminalId" />

        {kioskRole === "admin" && (
          <Label htmlFor="store">{t("ig-kiosk-admin:StoreLabel")}</Label>
        )}
        <StyledStoreInput
          type={kioskRole === "admin" ? "text" : "hidden"}
          name="store"
          id="store"
          defaultValue={store}
        />
        <ConfigFormErrorMessage error={error} fieldName="store" />

        {kioskRole === "admin" && (
          <Label htmlFor="storeUID">{t("ig-kiosk-admin:StoreUIDLabel")}</Label>
        )}
        <StyledInput
          type={kioskRole === "admin" ? "text" : "hidden"}
          name="storeUID"
          id="storeUID"
          defaultValue={storeUID}
        />
        <ConfigFormErrorMessage error={error} fieldName="storeUID" />

        <Label htmlFor="storeDepartment">
          {t("ig-kiosk-admin:StoreDepartmentLabel")}
        </Label>
        <StyledSelect
          name="storeDepartment"
          id="storeDepartment"
          defaultValue={storeDepartment}
        >
          {storeDepartmentOptions.map((storeDepartmentOption) => (
            <option key={storeDepartmentOption} value={storeDepartmentOption}>
              {storeDepartmentOption}
            </option>
          ))}
        </StyledSelect>

        <Label htmlFor="screenSaverVideoUrl">
          {t("ig-kiosk-admin:ScreenSaverVideoUrlLabel")}
        </Label>
        <StyledSelect
          name="screenSaverVideoUrl"
          id="screenSaverVideoUrl"
          defaultValue={screenSaverVideoUrl}
        >
          {Array.from(screenSaverOptions).map(
            ([screenSaverKey, screenSaverValue]) => (
              <option key={screenSaverKey} value={screenSaverValue}>
                {screenSaverKey}
              </option>
            )
          )}
        </StyledSelect>

        {kioskRole === "admin" && (
          <>
            <Label htmlFor="debugEnabledTime">
              <Message error>{t("ig-kiosk-admin:DebugLabel")}</Message>
            </Label>
            <StyledCheckbox
              name="debugEnabledTime"
              id="debugEnabledTime"
              defaultChecked={debugEnabledTime}
            />
          </>
        )}

        <StyledButtonContainer>
          <StyledButton
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm}
            complete={isSubmittingForm}
            type="submit"
          >
            {t("ig-kiosk-admin:SubmitButton")}
          </StyledButton>
          <StyledButton type="button" onClick={reload} link>
            {t("ig-kiosk-admin:Reset")}
          </StyledButton>
        </StyledButtonContainer>
        {showSuccess && (
          <StyledMessage success>
            {t("ig-kiosk-admin:SubmitSuccess")}
          </StyledMessage>
        )}
      </form>
    </StyledContainer>
  )
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 1rem;
  width: 22px;
  height: 22px;
`

const StyledInput = styled(Input)`
  margin-bottom: 1rem;
`

const StyledSelect = styled(Select)`
  margin-bottom: 1rem;
`

const StyledStoreInput = styled(StyledInput)`
  text-transform: uppercase;
`

const StyledButton = styled(Button)`
  margin-right: 1rem;
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const StyledMessage = styled(Message)`
  margin-top: 1rem;
`
